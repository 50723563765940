<template lang="pug">
  v-container#user-profile-config(fluid='' tag='section' style="padding: 0px;")
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-row(justify='center' style="width: 100%; margin: 0px;")
      v-col(cols='12' md='12' style="padding: 0px;")
        base-material-card.px-5.py-3(icon='mdi-account-check' title='Configuración de Usuarios' style='min-height: 100vh;')
          v-form(ref='formAddUser' v-model='validAddUser' lazy-validation='')
            v-row
              v-col(cols="12" md="4")
                v-row
                  v-col(cols='12')
                    v-text-field.purple-input(:disabled="userData.id" :rules='emailRules' label='Correo' v-model='userData.email')
                  v-col(cols='12')
                    v-text-field#logPassword(:disabled="userData.id" :rules='[rules.required, rules.min]' :append-icon="showPassReg ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassReg = !showPassReg" name='password' label='Contraseña' placeholder=' ' :type="showPassReg ? 'text' : 'password'" v-model='userData.password')
                  v-col(cols='12')
                    v-select(return-object v-model='selectAccount' :items="profileType" label="Seleccione el Perfil" item-text='texto')
                  v-col(cols="6" v-if="userData.id")
                    v-btn.mr-0(color='primary' @click='updateUser()' :loading="loadingCreateUser" style="width: 100%;")
                      | ACTUALIZAR USUARIO
                  v-col(cols="6" v-if="userData.id")
                    v-btn.mr-0(color='secondary' @click='newDataView()' :loading="loadingCreateUser" style="width: 100%;")
                      | NUEVO USUARIO
                  v-col(cols="12")
                    v-btn.mr-0( v-if="!userData.id" color='primary' @click='addNewUser()' :loading="loadingCreateUser" style="width: 100%;")
                      | AGREGAR USUARIO
              v-col(cols="12" md="8")
                v-text-field(v-model="search" append-icon="mdi-search" label= "Buscar" single-line="" hide-details="" clearable)
                v-data-table.mx-2#tablaUsuarios(style="margin-top: 20px;" :headers="headers" v-show="!firstLoad" :items="userLst" item-key="id" :search="search" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Usuarios por página'\}")
                  template(v-slot:item.estado="{ item }")
                    v-chip(:color="getColor(item.estado)" small label) {{ item.estado ? 'ACTIVO' : 'INACTIVO' }}
                  template(v-slot:item.action="{ item }")
                    v-tooltip(bottom="")
                      template(v-slot:activator="{ on }")
                        v-btn(text="" icon="" color='blue' v-on="on" @click="forgotPass(item)")
                          v-icon(small) mdi-account-key
                      span.tooltips Recuperar Contraseña
                    v-tooltip(bottom="")
                      template(v-slot:activator="{ on }")
                        v-btn(text="" icon="" color='green' v-on="on" @click="setDataView(item)")
                          v-icon(small) mdi-eye
                      span.tooltips Ver
                  template(v-slot:item.changeStatus="{ item }")
                    v-tooltip(v-if="!item.status || item.status === 'ACTIVO'" bottom="")
                      template(v-slot:activator="{ on }")
                        v-btn(text="" icon="" color='red' v-on="on" @click="changeProductInact(item)")
                          v-icon mdi-led-off
                      span.tooltips Desactivar
                    v-tooltip(v-if="item.status === 'INACTIVO'" bottom="")
                      template(v-slot:activator="{ on }")
                        v-btn(text="" icon="" color='green' v-on="on" @click="changeProductAct(item)")
                          v-icon mdi-led-on
                      span.tooltips Activar
                  //- Mensaje de alerta cuando no hay resultados
                  v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                    | Sin resultados
                  v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                    | Sin datos
                  v-flex(xs12='', sm10='', offset-sm1='')
</template>
<script>
  import firebase from 'firebase/app'
  export default {
    data: () => ({
      currentDay: new Date().toString().substr(4, 12),
      loadingCreateUser: false,
      validAddUser: true,
      headers: [
        { text: 'Email', value: 'email', align: 'left', filterable: true },
        { text: 'Perfil', value: 'profileType.texto', align: 'left', filterable: true },
        // { text: 'Contraseña', value: 'password', align: 'left', filterable: true },
        // { text: 'ESTADO', value: 'estado', align: 'left', filterable: true },
        { text: 'Activ/Desactiv', value: 'changeStatus', align: 'center', filterable: true },
        { text: 'ACCIONES', align: 'center', value: 'action', sortable: false },
      ],
      cargando: false,
      firstLoad: false,
      ordenarPor: 'email',
      search: '',
      selectAccount: {
        code: '001',
        texto: 'ADMINISTRADOR',
      },
      profileType: [
        {
          code: '001',
          texto: 'ADMINISTRADOR',
        },
        {
          code: '002',
          texto: 'FACTURACIÓN',
        },
      ],
      showPassReg: false,
      userData: {
        id: null,
        email: '',
        password: '',
        state: true,
      },
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      emailRules: [
        v => !!v || 'Correo requerido',
        v => /\S+@\S+\.\S+/.test(v) || 'Email inválido',
      ],
      rules: {
        required: value => !!value || 'Contraseña requerida',
        min: v => v.length >= 8 || 'Mínimo 8 caracteres',
        minPassword: value => {
          // const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
          return (
            pattern.test(value) ||
            'Min. 8 caracteres, 1 letra mayúscula, 1 minúscula y un número'
          )
        },
      },
    }),
    computed: {
      userLst () {
        return this.$store.state.user.userLst
      },
    },
    methods: {
      setDataView (item) {
        this.userData = item
      },
      newDataView (item) {
        this.userData = {
          id: null,
          email: '',
          password: '',
          state: true,
        }
      },
      updateUser () {
        firebase.database().ref(`users/${this.$store.state.user.user.uid}/userslst/${this.userData.id}/profileType`).set(this.selectAccount)
        firebase.database().ref(`users/${this.userData.uidUser}/profileType`).set(this.selectAccount)
        this.snackbar = {
          show: true,
          color: 'green',
          text: 'Usuario actualizado de manera correcta.',
        }
      },
      changeProductInact (item) {
        firebase.database().ref(`users/${this.$store.state.user.user.uid}/userslst/${item.id}/status`).set('INACTIVO')
        firebase.database().ref(`users/${item.uidUser}/status`).set('INACTIVO')
      },
      changeProductAct (item) {
        firebase.database().ref(`users/${this.$store.state.user.user.uid}/userslst/${item.id}/status`).set('ACTIVO')
        firebase.database().ref(`users/${item.uidUser}/status`).set('ACTIVO')
      },
      addNewUser () {
        if (this.$refs.formAddUser.validate()) {
          this.loadingCreateUser = true
          const userCreateData = {
            email: this.userData.email,
            password: this.userData.password,
            firstName: '',
            lastName: '',
            create: this.currentDay,
            status: true,
            accounts: [],
            selectAccount: '',
            valuidfac: '',
            userUidMain: '',
            profileType: this.selectAccount,
          }
          this.$store.dispatch('user/addNewUser', userCreateData).then(res => {
            this.loadingCreateUser = false
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Usuario agregado de manera correcta.',
            }
          }, reject => {
            this.loadingCreateUser = false
            if (reject.response.data.code.includes('already-exists')) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'El correo del usuario ya existe.',
              }
              return
            }
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al momento de agregar el usuario.',
            }
          })
        }
      },
      getColor (valor) {
        if (valor === true) {
          return 'green'
        } else {
          return 'red'
        }
      },
      forgotPass (item) {
        const emailToRecoverPass = item.email.trim()
        if (emailToRecoverPass !== '') {
          firebase.auth().sendPasswordResetEmail(emailToRecoverPass).then(() => {
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Correo enviado con éxito',
            }
          }).catch(() => {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Su correo no está registrado',
            }
          })
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'El campo correo no puede estar vacío',
          }
        }
      },
    },
    mounted () {
      this.selectAccount = {
        code: '001',
        texto: 'ADMINISTRADOR',
      }
    },
  }
</script>
